import IconHelper from "../IconMaterial";
import style from "./style.module.scss";
import {langArray} from "../../../utils/languages"
import { LabelCheckbox } from "./CustomCheckbox";

export default function SearchBar({ extraStyles, onChange, ...props }) {
  return (
    <div className={style.searchBar} style={extraStyles} >                                      
      <input placeholder="Suche" onChange={onChange} {...props} />
      <IconHelper iconName={"search"} className={style.searchIcon}/>
    </div>
  );
}
