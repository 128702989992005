import { BeatLoader } from "react-spinners";

export default function Loader({ type, variant }) {

  return (
    <div
      className={`loaderWrapper ${variant == "welcome" ? "f-loader" : null}`}
    >
     
     <img src="/loading_logo.png" className="loader" /> 
     
    </div>
  );
}
