import { useState, useEffect } from "react";
import { germanAbilityLevels } from "../../../utils/GeneralData";
import { useRouter } from "next/router";
import useApiLink from "../ApiLink";
import { useActiveUser, useDetails, useSelectedUser, useToken } from ".";
import axios from "axios";
import useDataRequest from "../../../utils/AxiosInstance";

export function useGermanSkills(germanSkill) {
  const [skill, setSkill] = useState("");
  useEffect(() => {
    const fil = germanAbilityLevels.filter((skill) => {
      return skill.value == germanSkill;
    });
    setSkill(fil.map((el) => el.label));
  }, [germanSkill]);

  return [skill];
}

export function useUserType() {
  const [userDetails] = useDetails();
  const [user, setUser] = useState(null);

  useEffect(() => {
    const accountType = userDetails.accountType?.toLowerCase();
    setUser(accountType == "curagita" ? "admin" : accountType);
  }, [userDetails]);

  return user;
}

export function useUserRole() {
  const [userDetails] = useDetails();
  const [user, setUser] = useState(null);

  useEffect(() => {
    const accountType = userDetails.accountType?.toLowerCase();
    setUser(accountType);
  }, [userDetails]);

  return user;
}

export function useAuthUser() {
  const [userDetails] = useDetails();
  const [user, setUser] = useState(null);
  const [selectedUser] = useSelectedUser();

  useEffect(() => {
    const selected = selectedUser;

    const accountType = selected.token
      ? selected.accountType?.toLowerCase()
      : userDetails.accountType?.toLowerCase();

    setUser(accountType == "curagita" ? "admin" : accountType);
  }, [userDetails, selectedUser]);

  return user;
}

export function useRole() {
  const [role, setRole] = useState("");
  const [activeUser] = useActiveUser();
  const user = useAuthUser();

  useEffect(() => {

    if (activeUser.role?.toLowerCase() === "super-admin") {
      setRole("super-admin");
    } else {
      switch (activeUser.role?.toLowerCase()) {
        case "manager":
        case "recruiter":
        case "super-admin":
          setRole("admin");
          break;
        default:
          setRole(activeUser.role);
          break;
      }
    }
  }, [activeUser?.role, user]);

  return role;
}

export function useLinkPrefix() {
  const userType = useUserType();
  const [prefix, setPrefix] = useState("");

  useEffect(() => {
    if (userType == "admin") setPrefix("/admin");
    else if (userType == "partner") setPrefix("/partner");
    else if (userType == "candidate") setPrefix("/candidate");
    else setPrefix("");
  }, [userType]);

  return prefix;
}

export function useAllJobs(orgId) {
  const [jobs, setJobs] = useState([]);
  const [loading, setLoading] = useState(true);
  const ApiLink = useApiLink();
  const token = useToken();

  useEffect(() => {
    setLoading(true);
    axios
      .get(`${ApiLink}/job/search-jobs/${orgId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setJobs(response.data.data);
      })
      .catch(() => {
        console.error;
      })
      .finally(() => setLoading(false));
  }, [ApiLink, token, orgId]);

  const deleteJobById = (jobId) => {
    const updatedjobs = jobs.filter((job) => job._id !== jobId);
    setJobs(updatedjobs);
  };

  return [jobs,  loading, setJobs, deleteJobById];
}

export function useAllLocations(orgId) {
  const { data: locations } = useDataRequest(`user/company/${orgId}/locations`);
  return [locations];
}

export function useAllSubusers(orgId) {
  const { data: subusers = [], loading } = useDataRequest(
    `user/company/${orgId}/sub-users`
  );
  return [subusers, loading];
}

export function useArrayFilter(data, filters) {
  const [filtered, setFiltered] = useState(data);

  useEffect(() => {
    const newFiltered = data.filter((item) => {
      return filters.every((filter) => {
        const [filterKey, filterValues] = filter;
        return filterValues.includes(item[filterKey]);
      });
    });

    setFiltered(newFiltered.length ? newFiltered : data);
  }, [data, filters]);

  return filtered;
}

export const useCategoriesMeaning = (category) => {
  const [meaning, setMeaning] = useState("");

  useEffect(() => {
    switch (category) {
      case "MTR":
        setMeaning("Medizinische Technolog/-in für Radiologie ");
        break;
      case "MFA":
        setMeaning("Medizinische Fachangestellte");
        break;
      case "MTL":
        setMeaning("Medizinische Technologin für Laboratoriumsassistenten");
        break;
      case "MFA":
        setMeaning("Medizinische fachangestellte");
        break;
      case "CTA":
        setMeaning("Chemisch-technische Assistenten");
        break;
      default:
        setMeaning(category);
        break;
    }
  }, [category]);

  return meaning;
};

export const useFormattedPrice = (amount) => {
  return amount ? (
    <>
      <span>{Math.ceil(amount).toLocaleString("de-DE")}</span> <span>€</span>
    </>
  ) : (
    ""
  );
};

