import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import InfoModal from '../InfoModal';

export const FormAlert = ({ isDirty, isSubmitting}) => {
    // isDirty is a boolean value to check if the form has been touched or not. If you have no way to keep track, you can set it to true as default
    // isSubmitting is a boolean value to check if the form is submitting

  const router = useRouter();
  const [show, setShow] = useState(false)
  const [pendingRoute, setPendingRoute] = useState(null);
  const [isConfirmed, setIsConfirmed] = useState(false); // Flag to track user confirmation

  const handleRouteChange = (url) => {
    if (isDirty && !isSubmitting && !isConfirmed) {
      setShow(true)
      setPendingRoute(url); // Store the route user is attempting to navigate to
      router.events.emit('routeChangeError'); // Stop the route change
      throw 'Route change aborted'; // Prevent navigation
    }
  };

  useEffect(() => {
    // Add event listener for route changes
    router.events.on('routeChangeStart', handleRouteChange);

    // Add event listener for page reload or tab close
    const handleBeforeUnload = (e) => {
      if (isDirty && !isSubmitting) {
        const confirmationMessage =
          'Sie haben nicht gespeicherte Änderungen. Sind Sie sicher, dass Sie gehen möchten?';
        e.returnValue = confirmationMessage; // Standard for most browsers
        return confirmationMessage;
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    // Clean up the event listeners on unmount
    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isDirty, isSubmitting, isConfirmed, router.events]);

  return (
    show && (
        <InfoModal
          closeModal={() => setShow(false)}
          info="Ohne Speichern schließen"
          extra="Sie haben nicht gespeicherte Änderungen. Sind Sie sicher, dass Sie gehen möchten?"
          onNoClick={() => {
            setShow(false);
            setPendingRoute(null); 
          }}
          action={() => {
            setShow(false);
            setIsConfirmed(true);
            setTimeout(() => {
                router.push(pendingRoute);
            }, 500);
          }}
        />
    )
  );
};