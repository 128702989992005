import style from "../../styles/reusable.module.scss";

export default function Chip({
  label,
  variant,
  bgColor,
  width,
  onClick,
  styles,
  className,
  disabled
}) {
  const chipBg = {
    ausstehend: "var(--color-yellow)",
    akzeptiert: "var(--color-green)",
    abgeleht: "#818181",
    storniert: "var(--color-red)",
    geschlossen: "var(--color-red)",
    pausiert: "var(--color-yellow)",
    offen: "var(--color-green)",
  };
  const text = typeof label == "string" ? label : ""
  return (
    <button
      type="button"
      className={`${style.chip} ${className} ${
        variant == "bg-none" ? style.bgNone : style[variant]
      }`}
      style={{
        background: variant
          ? null
          : chipBg[text?.toLowerCase()]
          ? chipBg[text?.toLowerCase()]
          : bgColor,
        color: bgColor || chipBg[text?.toLowerCase()] ? "#fff" : null,
        width: width,
        cursor: onClick ? "pointer" : "auto",
        ...styles,
      }}
      onClick={onClick ?? null}
      disabled={disabled}
    >
      {label ?? null}
    </button>
  );
}

export  function Tag({
  label,
  active,
  onClick,
  disabled
}) {

  
  return (
    <button
      type="button"
      className={`${style.tag} ${active ? style.tagActive : null}`}
      onClick={onClick ?? null}
      disabled={disabled}
    >
      {label ?? null}
    </button>
  );
}