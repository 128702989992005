import React, { createContext, useContext, useEffect, useState } from "react";
import { withPageAuthRequired, useUser } from "@auth0/nextjs-auth0";
import axios from "axios";
import Loader from "../reusable/Loader";
const UserContext = createContext();

const LocalUserProviderComponent = ({ children }) => {
  const { user, error, isLoading } = useUser();
  const [userInfo, setUserInfo] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const storage = window.localStorage;
    if (!isLoading && !user?.sid) {
      setUserInfo(null);
      setLoading(true)
    } else if (storage.getItem("token")) {
      const details = JSON.parse(storage.getItem("userDetails"));
      setUserInfo({
        ...details,
        token: storage.getItem("token"),
        user: storage.getItem("user"),
      });
      setLoading(false)
    } else if (user?.sid && !isLoading) {
      setLoading(true);
      axios.get("/api/login")
        .then((res) => {
          const userInfo = res.data.data;
          storage.setItem("userDetails", JSON.stringify(userInfo));
          storage.setItem("token", userInfo.token);
          storage.setItem("user", userInfo.accountType.toLowerCase());

          setUserInfo(userInfo);
        })
        .catch(console.error)
        .finally(() => setLoading(false));
    }
  }, [user, isLoading]);

  return (
    <UserContext.Provider value={{ userInfo, setUserInfo, error, isLoading, loading, setLoading }}>
      {loading || isLoading ? <Loader /> : children}
    </UserContext.Provider>
  );
};
export const LocalUserProvider = withPageAuthRequired(
  LocalUserProviderComponent
);

export const useLocalUser = () => {
  return useContext(UserContext);
};
