import style from "./style.module.scss";
import { FileInput } from "./Upload";
import { FloatingLabelInput } from "./Inputs";
import IconWrapper from "../Icons";

export default function DocumentUpload({ doc = {}, setDoc, del=true }) {
  return (
    <div className="d-flex gap-16 align-items-center">
      <FileInput
        id={`url`}
        value={doc.url}
        buttonText={"Dokument"}
        type="file"
        btnStyle={{ width: "163px" }}
        handleChange={(val) => setDoc({ ...doc, url: val })}
      />
      <div className="input-sm">
        <FloatingLabelInput
          label="Anzeigename"
          value={doc.docName}
          onChange={(e) => setDoc({ ...doc, docName: e.target.value })}
        />
      </div>
      {del && (
        <IconWrapper
          iconName={"delete"}
          onClick={() => setDoc({ url: "", docName: "" })}
        />
      )}
    </div>
  );
}
