import style from "../../styles/Modal.module.scss";
import IconWrapper from "./MaterialIcon";
import { Button } from "./FormComponent";
import { useEffect } from "react";

export default function InfoModal({
  closeModal,
  info,
  extra,
  action,
  onNoClick,
  type,
  mdSize,
  children,
  width=null
}) {

  useEffect(() => {
    document.body.style.overflow = "hidden";
    document.body.style.position = "fixed";

  // Cleanup when the component unmounts
  return () => {
    document.body.style.overflow = "auto";
    document.body.style.position = "static";
  };
}, []);


  return (
    <div className={style.myModal} onClick={closeModal}>
      <div
        className={`${style.innerContent} ${mdSize}`}
        style={{width: width}}
        onClick={(e) => e.stopPropagation()}
      >
        <div className={style.times} onClick={closeModal}>
          <IconWrapper iconName={"close"} onClick={closeModal} />
        </div>
        <p className={style.main_text}>{info}</p>
        <p className={style.sub_text} style={{fontWeight: "300"}} >{extra}</p>
        {children}
        <div className={style.btn_group} style={{width: type == "warning" ? "" : type == "info" ? "" : "210px"}}>
          {type == "warning" ? null : type == "info" ? (
            <Button
              onClick={closeModal}
              btnText="Schließen"
              variant="bg-blue"
              className="mt-32"
            />
          ) : (
            <>
            <Button
              onClick={() => {
                onNoClick && onNoClick()
                closeModal()
              }}
              btnText="Nein"
              variant="bg-outline"
              styles={{flex:1}}
            />
            <Button
              onClick={action}
              btnText="Ja"
              variant="bg-blue"
              styles={{flex:1}}
            />
            </>
          )}
        </div>
      </div>
    </div>
  );
}
