import axios from "axios";
import { useState, useEffect, useCallback, useRef } from "react";
import useApiLink from "../src/reusable/ApiLink";
import { useToken } from "../src/reusable/CustomHooks";

export default function useDataRequest(
  endpoint,
  options = null,
  extraOptions,
  returnWholeResponse
) {
  const ApiLink = useApiLink();
  const token = useToken();
  const [data, setData] = useState(null);
  const [metaData, setMetaData] = useState(null);
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!endpoint) return;

    const fetchData = async () => {
      setLoading(true);
      setError(false);
      setErrorText("");
      if(endpoint){
      try {
        // Conditional axios call based on whether options exist
        const response = await (options
          ? axios.get(`${ApiLink}/${endpoint}`, {
              params: options,
              headers: {
                Authorization: `Bearer ${token}`,
              },
            })
          : axios.get(`${ApiLink}/${endpoint}`, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }));

        setMetaData(response.data.metadata);
        returnWholeResponse
          ? setData(response.data)
          : response.data.data !== undefined
          ? setData(response.data.data)
          : setData(response.data);
      } catch (error) {
        console.error(error.response?.data);
        setError(true);
        setErrorText(error.response?.data?.message);
      } finally {
        Promise.resolve().then(() => {
          setLoading(false);
        });
      }}
    };

    fetchData();
  }, [endpoint, ApiLink, options, extraOptions, token]);

  return {
    data,
    setData,
    metaData,
    setMetaData,
    error,
    loading,
    setError,
    errorText,
  };
}

export function useDataRequestWithOptions(
  endpoint,
  options,
  extraOptions,
  returnWholeResponse
) {
  const ApiLink = useApiLink();
  const token = useToken();
  const [data, setData] = useState(null);
  const [metaData, setMetaData] = useState(null);
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(false);
      setErrorText("");
      try {
        const response = await axios(`${ApiLink}/${endpoint}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setMetaData(response.data.metadata);
       returnWholeResponse
          ? setData(response.data)
          : response.data.data !== undefined
          ? setData(response.data.data)
          : setData(response.data);
      } catch (error) {
        console.error(error.response);
        setError(true);
        setErrorText(error.response?.data?.message);
      } finally {
        // ensure data state has fully set before removing loader
        setTimeout(() => {
          setLoading(false);
        }, 500);
      }
    };

    fetchData();
    return () => {};
  }, [endpoint, ApiLink, options, extraOptions, token]);

  return {
    data,
    setData,
    metaData,
    setMetaData,
    error,
    loading,
    setError,
    errorText,
  };
}


// this shows loader only on first load
export function useModifiedDataRequest(
  endpoint,
  options,
  extraOptions,
  returnWholeResponse,
  dependencies=[]
) {
  const ApiLink = useApiLink();
  const token = useToken();
  const [data, setData] = useState(null);
  const [metaData, setMetaData] = useState(null);
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [loading, setLoading] = useState(true);
  const [firstLoad, setFirstLoad] = useState(true); // Track first load

  const fetchData = async () => {
    if (firstLoad) {
      setLoading(true); // Show loader on the first load
    }
    setError(false);
    setErrorText("");

    try {
      const response = await axios(`${ApiLink}/${endpoint}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setMetaData(response.data.metadata);
      returnWholeResponse
        ? setData(response.data)
        : response.data.data !== undefined
        ? setData(response.data.data)
        : setData(response.data);
    } catch (error) {
      console.error(error.response);
      setError(true);
      setErrorText(error.response?.data?.message || "An error occurred.");
    } finally {
      // Ensure data state has fully set before removing loader
      if (firstLoad) {
        setTimeout(() => {
          setLoading(false);
          setFirstLoad(false); // Mark that the first load has completed
        }, 500);
      }
    }
  };

  useEffect(() => {
    fetchData();
    // Cleanup function if needed
    return () => {};
  }, [endpoint, ApiLink, options, extraOptions, token, ...dependencies]);

  return {
    data,
    setData,
    metaData,
    setMetaData,
    error,
    loading,
    setError,
    errorText,
    fetchData, // Expose fetchData for manual calls without loader
  };
}

// add p-limit before final launch to batch multiple requests
export function useMultipleDataRequest(endpoint, arr, deps) {
  const ApiLink = useApiLink();
  const token = useToken();
  const [data, setData] = useState([]);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const prevArrRef = useRef();

  useEffect(async () => {
    const prevArr = prevArrRef.current;
    setError(false);

    if (ApiLink && token) {
      if (arr && prevArr !== arr) {
        const requests = arr.map((el) =>
          axios(`${ApiLink}${endpoint}/${el}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
        );

        Promise.allSettled(requests)
          .then((results) => {
            const res = results.map((result) => {
              if (result.status === "fulfilled") {
                return result.value.data.data
                  ? result.value.data.data
                  : result.value.data;
              } else {
                console.error(`Request failed:`, result.reason);
                return null;
              }
            });

            setData(res.filter((item) => item !== null));
          })
          .catch(console.error)
          .finally(() => {
            setLoading(false);
          });
      }
    }
  }, [endpoint, ApiLink, token, deps]);

  return { data, setData, error, loading, setError };
}

export function useRequestsHandler() {
  const ApiLink = useApiLink();
  const token = useToken();
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const [response, setResponse] = useState({});
  const [putResponse, setPutResponse] = useState(null);

  const handlePutRequest = useCallback(
    async (endpoint, payload, handleSuccess, handleError, modals) => {
      setError(false);
      setErrorMsg("");
      setPutResponse(null);
      !modals && setLoading(true);
      try {
        const res = await axios.put(`${ApiLink}/${endpoint}`, payload, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setPutResponse(res?.data?.data ? res?.data?.data : res?.data);
        !modals && setSuccess(true);
        if (handleSuccess)
          handleSuccess(res?.data?.data ? res?.data?.data : res?.data);
      } catch (err) {
        console.log(err);
        setErrorMsg(err.response.data?.message || "An error occurred");
        !modals && setError(true);
        if (handleError) handleError();
      } finally {
        !modals && setLoading(false);
      }
    },
    [token]
  );

  const handlePostRequest = useCallback(
    async (endpoint, payload, handleSuccess, handleError) => {
      setLoading(true);
      setError(false);
      setErrorMsg("");

      try {
        const response = await axios.post(`${ApiLink}/${endpoint}`, payload, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setResponse(response.data);
        setSuccess(true);
        if (handleSuccess) {
          response.data.data !== undefined
            ? handleSuccess(response.data.data)
            : handleSuccess(response.data);
        }
      } catch (err) {
        if (handleError) handleError();
        setErrorMsg(err.response.data?.message || "An error occurred");
        setError(true);
      } finally {
        setLoading(false);
      }
    },
    [token]
  );

  const handlePatchRequest = useCallback(
    async (endpoint, payload, handleSuccess, handleError) => {
      setLoading(true);
      setError(false);
      setErrorMsg("");

      try {
        const response = await axios.patch(`${ApiLink}/${endpoint}`, payload, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setData(response);
        setSuccess(true);
        if (handleSuccess) handleSuccess(response);
      } catch (err) {
        setErrorMsg(err.response?.data?.message || "An error occured");
        setError(true);
        if (handleError) handleError();
      } finally {
        setLoading(false);
      }
    },
    [token]
  );

  const handleDeleteRequest = (endpoint, handleSuccess, handleError) => {
    setLoading(true);
    setError(false);
    setErrorMsg("");
    axios
      .delete(`${ApiLink}/${endpoint}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (handleSuccess) handleSuccess(res);
        setLoading(false);
        setSuccess(true);
      })
      .catch((err) => {
        if (handleError) handleError();
        setLoading(false);
        setErrorMsg(err.response.data?.message || "An error occured");
        setError(true);
      });
  };

  return {
    loading,
    handlePutRequest,
    success,
    setSuccess,
    error,
    data,
    setError,
    handleDeleteRequest,
    handlePostRequest,
    handlePatchRequest,
    response,
    errorMsg,
    setErrorMsg,
    putResponse,
  };
}
