import React, { useEffect, useState, useRef } from "react";
import NextNavLink from "../NavLink";
import { useRouter } from "next/router";
import Loader from "../Loader";
import style from "../../../styles/Header.module.scss";
import {
  useActiveUser,
  useCuraPartner,
  useDetails,
  useGlobalUser,
} from "../CustomHooks";
import {
  useAuthUser,
  useLinkPrefix,
  useRole,
  useUserType,
} from "../CustomHooks/others";
import Navigation from "./Navigation";
import { Person } from "@mui/icons-material";
import useRoutes from "./routes";
import SearchUser from "./SearchUser";
import { ClickAwayListener } from "@material-ui/core";
import IconHelper from "../IconMaterial";
import { NavItem } from "./components";
import { useLocalUser } from "../../AuthContext/UserContext";
import useDataRequest from "../../../utils/AxiosInstance";
import { act } from "react";

const getToolName = (path, routes = []) => {
  for (let item of routes) {
    if (item.path === path) {
      return item.title;
    }
    if (item.list) {
      for (let subItem of item.list) {
        if (subItem?.path === path) {
          return item.title;
        }
        if (subItem?.list) {
          for (let subItemSub of subItem.list) {
            if (subItemSub.path === path) {
              return item.title;
            }
          }
        }
      }
    }
  }
  return null;
};

const getToolAssistant = (path, routes = [], parentListItem, childListItem) => {
  for (const item of routes) {
    if (item.path === path && item.toolAssistant) {
      return item.toolAssistant;
    }
    const found = item?.list?.find(
      (subItem) => subItem.path === path && subItem.toolAssistant
    );
    if (found) {
      return found.toolAssistant;
    }
  }
  return childListItem?.toolAssistant || parentListItem?.toolAssistant;
};

const getLastPathSegment = (path, userType) => {
  const segments = path.split("/").filter((segment) => segment !== "");

  if (path.includes("/planner/language")) return "language";
  if (path.includes("/planner")) return "planner";
  if (path.includes("/profile/[profileId]")) return "search";
  if (path.includes("/curatech/vergleich/comparison")) return "comparison";
  if (path.includes("/curatech/vergleich/alle-gerate")) return "alle-gerate";
  if (path.includes("/events/teilnehmende")) return "teilnehmende";
  if (path.includes("/konto/form")) return "konto";
  if (path.includes("/konto/org")) return "org";
  if (path.includes("/konto/location")) return "org";
  if (path.includes("/konto/berechtigungen")) return "berechtigungen";
  if (path.includes("/konto/benachrichtigungen")) return "benachrichtigungen";
  if (path.includes("/know-how/articles/favourites"))
    return "favourite-articles";
  if (path.includes("/know-how/articles/table")) return "articles-table";
  if (path.includes("/know-how/articles")) return "all-articles";
  if (path.includes("/curafinance/dcf-rechnung")) return "dcf-rechnung";
  if (path.includes("/curafinance/benchmarking")) return "benchmarking";
  if (path.includes("curaprotect/anbindungen")) return "protect-anbindungen";
  if (path.includes("curahr/anbindungen")) return "hr-anbindungen";
  if (path.includes("curashop/anbindungen")) return "shop-anbindungen";
  if (path.includes("curatalent/anbindungen")) return "talent-anbindungen";
  if (path.includes("finance/anbindungen")) return "finance-anbindungen";
  if (path.includes("netz/anbindungen")) return "netz-anbindungen";
  if (path.includes("tech/anbindungen")) return "tech-anbindungen";
  if (path.includes("how/anbindungen")) return "how-anbindungen";
  if (path.includes("scan/anbindungen")) return "scan-anbindungen";
  if (path.includes("comm/anbindungen")) return "comm-anbindungen";
  if (path.includes("fee/anbindungen")) return "fee-anbindungen";
  if (path.includes("consult/anbindungen")) return "consult-anbindungen";
  if (path.includes("curait/anbindungen")) return "it-anbindungen";
  if (path.includes("curagita/anbindungen")) return "curagita-anbindungen";

  if (!userType) {
    return segments[0] || null;
  }
  const formattedUserType = userType.startsWith("/")
    ? userType.slice(1)
    : userType;
  const userTypeIndex = segments.indexOf(formattedUserType);
  if (userTypeIndex !== -1 && userTypeIndex < segments.length - 1) {
    return segments[userTypeIndex + 1];
  }
  return null;
};

const getNavItemFromToolName = (routes, toolName) => {
  let item = routes?.filter((x) => x?.title === toolName);
  return item[0];
};

const getSubNavItemFromNavItem = (routes, navItem) => {
  let item = routes?.filter((x) => x?.title === navItem);
  return item[0];
};

const getToolSubTitle = (path, navItem) => {
  let x = navItem?.list || [];
  for (let item of x) {
    if (item.path === path) {
      return item.title;
    }
    if (item.list) {
      for (let subItem of item.list) {
        if (subItem.path === path) {
          return item.title;
        }
      }
    }
  }
  return null;
};

function NavBar({}) {
  const router = useRouter();
  const role = useRole();
  const [swappedRoute, setSwappedRoute] = useState(null);
  const [extraTool, setExtraTool] = useState(false);
  const { setUserInfo } = useLocalUser();
  const userType = useAuthUser();
  const [authUserDetails] = useDetails();
  const [activeUser] = useActiveUser();
  const [userDetails] = useGlobalUser(activeUser._id);
  const [loading, setLoading] = useState(false);
  const prefix = useLinkPrefix();
  const navRefs = useRef([]);
  const user = useUserType();
  const [dropdownPositions, setDropdownPositions] = useState({});
  const { routes } = useRoutes(userDetails);
  const [open, setOpen] = useState(false);
  const { pathname } = useRouter();
  const currentPath = getLastPathSegment(pathname, prefix);
  const tool = getToolName(currentPath, routes);
  const [openContact, setOpenContact] = useState(false);
  let activeNav = getNavItemFromToolName(routes, tool);
  let currentToolSubtitle = getToolSubTitle(currentPath, activeNav);
  let activeSubNav = getSubNavItemFromNavItem(
    activeNav?.list || [],
    currentToolSubtitle
  );
  const [curapartner] = useCuraPartner(activeNav?.toolName?.toLowerCase());
  const [displayAnbindungen, setDisplayAnbindungen] = useState(false);

  let currentToolSubtitleSub = getToolSubTitle(currentPath, activeSubNav);
  const toolAssistant =
    getToolAssistant(currentPath, routes, activeNav, activeSubNav) || {};

  const logOut = async () => {
    setOpen(false);
    setLoading(true);
    window.localStorage.clear();
    window.sessionStorage.clear();
    await window.location.replace(
      `/api/auth/logout?returnTo=${encodeURIComponent(
        process.env.NEXT_PUBLIC_LOGOUT_URL
      )}`
    );
    setUserInfo(null);
    setLoading(false);
  };

  useEffect(() => {
    const handleResize = () => {
      const viewportHeight = window.innerHeight;
      const newPositions = {};

      navRefs.current.forEach((navItem, index) => {
        if (navItem) {
          const rect = navItem.getBoundingClientRect();
          if (rect.bottom > viewportHeight) {
            newPositions[index] = {
              top: "auto",
              bottom: 0,
            };
          } else {
            newPositions[index] = {
              top: rect.top,
              bottom: "auto",
            };
          }
        }
      });

      setDropdownPositions(newPositions);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, [routes]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const filteredRoutes = routes?.filter((x) => x?.isTool === true);

  const displayedRoutes = swappedRoute
    ? [...filteredRoutes, swappedRoute]
    : filteredRoutes;

  const unfilteredRoutes = routes?.filter(
    (x) => x?.title !== "Konto" && !displayedRoutes.includes(x)
  );

  useEffect(() => {
    const currentTool = routes.find(
      (route) => route.toolName === activeNav?.toolName
    );

    if (currentTool && !currentTool.isTool && currentTool.title !== "Konto") {
      setSwappedRoute(currentTool);
    }
  }, [activeNav?.toolName]);

  useEffect(() => {
    if (
      (userType !== "customer" && userType !== "admin") ||
      (userType !== "admin" && curapartner?.length == 0)
    )
      setDisplayAnbindungen(false);
    else setDisplayAnbindungen(true);
  }, [curapartner, user]);


  return (
    <>
      {loading ? <Loader variant="welcome" /> : null}
      <>
        <div className={style.logoWrapper}>
          <img
            alt="logo"
            src="/curagita-logo.svg"
            className={style.logo}
            onClick={() => router.push(`${prefix}/know-how/articles`)}
          />
        </div>

        <nav className={style.navWrapper}>
          <div className="d-flex align-items-center">
            {/*******************TOP NAV (TOOL NAME) TITLE**************/}
            <div className={style.toolName}>
              {activeNav &&
                (activeNav.iconName == "curagita" ? (
                  <img
                    alt="curagita"
                    src="/curagita-logo.png"
                    style={{ height: "22.7px", width: "30px" }}
                  />
                ) : (
                  <IconHelper
                    iconName={activeNav.iconName}
                    size={32}
                    variant={!activeNav.isTool ? "filled" : null}
                    className={style.activeNavIcon}
                  />
                ))}
              <h1>{activeNav?.displayedToolname || activeNav?.toolName}</h1>
            </div>

            {/******************TOP NAV ELEMENTS*********************/}
            <div className={style.toolNavItems}>
              {activeNav?.list.map((listItem, idx) => (
                <NavItem
                  path={pathname}
                  listItem={listItem}
                  currentToolSubtitle={currentToolSubtitle}
                  key={idx}
                  displayAnbindungen={displayAnbindungen}
                  activeSubNav={activeSubNav}
                  currentToolSubtitleSub={currentToolSubtitleSub}
                  toolName={activeNav?.displayedToolname || activeNav?.toolName}
                />
              ))}
            </div>
          </div>

          <div className="d-flex align-items-center">
            {/****************USERNAME DISPLAY******************/}
            {authUserDetails.role == "super-admin" ? (
              <SearchUser activeUser={activeUser} />
            ) : (
              <p
                className="px-4 fw-500"
                style={{ color: "var(--curagita-blue)" }}
              >
                {`${userDetails.vorname ?? ""} ${userDetails.nachname ?? ""}`}{" "}
              </p>
            )}
            <ClickAwayListener onClickAway={() => setOpen(false)}>
              <div className={style.navBtnWrapper}>
                <button
                  className={style.userIcon}
                  onClick={() => setOpen(!open)}
                >
                  <Person
                    style={{
                      width: "24px",
                      height: "24px",
                      color: "var(--curagita-blue)",
                    }}
                  />
                </button>
                {open && (
                  <div
                    className={style.navDropdown}
                    style={{ right: 0, left: "auto", top: "48px" }}
                  >
                    <NextNavLink href={`${prefix}/konto`}>
                      <a
                        className={style.navLink}
                        onClick={() => setOpen(false)}
                      >
                        Konto
                      </a>
                    </NextNavLink>

                    <button className={style.navLink} onClick={logOut}>
                      Abmelden
                    </button>
                  </div>
                )}
              </div>
            </ClickAwayListener>
          </div>
        </nav>

        <nav className={style.sideNav}>
          {/********************SIDE NAV ELEMENTS**********************/}
          {displayedRoutes.map((path, index) => (
            <Navigation
              nav={path}
              key={index}
              index={index}
              navRef={(el) => (navRefs.current[index] = el)}
              styles={dropdownPositions[index]}
              tool={tool}
              swappedRoute={swappedRoute}
              onNavClick={() => {
                if (path !== swappedRoute) {
                  setSwappedRoute(null);
                }
              }}
            />
          ))}
          {/****************EXTRA ROUTES IN MODAL**********************/}
          {unfilteredRoutes && unfilteredRoutes?.length !== 0 && (
            <ClickAwayListener onClickAway={() => setExtraTool(false)}>
              <div className={style.navBtnWrapper}>
                <button
                  className={`${style.navBtn} ${style.more}`}
                  onClick={() => setExtraTool(!extraTool)}
                >
                  <IconHelper iconName={"more_horiz"} size={32} />
                </button>
                {extraTool && (
                  <div
                    className={style.navDropdown}
                    style={{ width: "224px", padding: "20px 8px 8px 8px" }}
                  >
                    <h5 className="fs-14 pl-3">Anbindungen</h5>
                    <div className="row mx-0 gap-8 ">
                      {unfilteredRoutes.map((route, index) => (
                        <button
                          key={index}
                          style={{ width: "64px", paddingBottom: "4px" }}
                          className={style.navBtn}
                          onClick={() => {
                            setSwappedRoute(route);

                            setExtraTool(false);
                            router.push(
                              `${prefix}/${
                                route.list[0]?.specifiedPath
                                  ? route.list[0]?.specifiedPath
                                  : route.list[0]?.path
                              }`
                            );
                          }}
                        >
                          {route.iconName == "curagita" ? (
                            <img
                              alt="curagita"
                              src="/curagita-logo.svg"
                              className={style.logo}
                            />
                          ) : (
                            <IconHelper iconName={route.iconName} size={32} />
                          )}
                          <p className={style.navText}>{route.title}</p>
                        </button>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </ClickAwayListener>
          )}
        </nav>
        <button className={style.toTop} onClick={() => scrollToTop()}>
          <IconHelper iconName={"keyboard_arrow_up"} />
        </button>
        {activeNav?.isTool && (
          <ClickAwayListener onClickAway={() => setOpenContact(false)}>
            <div className={style.contactButtonWrapper}>
              <button
                className={`${style.contactButton} ${
                  openContact ? style.contactBtnActive : null
                }`}
                onClick={() => setOpenContact(!openContact)}
              >
                <IconHelper iconName={"support_agent"} size={32} />
              </button>

              {toolAssistant && openContact ? (
                <div className={style.contactPopup}>
                  <div className={style.contactImg}>
                    <img src={`/toolsAssistants/${toolAssistant.img}`} />
                  </div>
                  <div className={style.contactDetails}>
                    <h3 className={style.name}>{toolAssistant.name}</h3>
                    <div className={style.contactInfo}>
                      <div>
                        <IconHelper iconName={"mail"} />
                        <a href={`mailto:${toolAssistant.email}`}>
                          {toolAssistant.email}
                        </a>
                      </div>
                      <div>
                        <IconHelper iconName={"call"} />
                        <a href={`tel:${toolAssistant.phone}`}>
                          {toolAssistant.phone}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </ClickAwayListener>
        )}
      </>
    </>
  );
}

export default NavBar;
