import { useRouter } from "next/router";
import { services } from "../../../utils/GeneralData";
import { useAuthUser, useRole, useUserType } from "../CustomHooks/others";
import { useEffect, useState } from "react";

export default function useRoutes(userDetails = {}) {
  const router = useRouter();
  const { id } = router.query; //for planner
  const user = useAuthUser();
  const role = useRole();
  const loggedInUser = useUserType();

  const defaultRoutes = [
    {
      title: "Know-How",
      optToolName: "know_how",
      toolName: "Know-How",
      iconName: "import_contacts",
      path: "know-how",
      isTool: true,
      toolAssistant: {
        name: "Luisa Schmidt-Tophoff",
        email: "lst@curagita.com",
        phone: "+49 152 0152 5200",
        img: "Luisa-Schmidt-Tophoff.jpg",
      },
      list: [
        {
          title: "Übersicht",
          path: "know-how",
        },
        {
          title: "Alle Artikel",
          path: "know-how/articles",
          list: [
            {
              title: "Alle Artikel",
              path: "all-articles",
              specifiedPath: `know-how/articles`,
            },
            ...services.map((x) => ({
              title: x,
              path: x,
              specifiedPath: `know-how/articles/${x}`,
            })),
          ],
        },
        {
          title: "Favoriten",
          path: "favourite-articles",
          specifiedPath: "know-how/articles/favourites",
        },
        user === "admin" || user === "curagita"
          ? {
              title: "Admin",
              path: "articles-table",
              specifiedPath: "know-how/articles/table",
            }
          : null,
        {
          title: "Anbindungen",
          path: "how-anbindungen",
          specifiedPath: "know-how/anbindungen",
        },
      ].filter((item) => item !== null),
    },
    {
      title: "Finance",
      toolName: "CuraFinance",
      iconName: "bar_chart_4_bars",
      isTool: true,
      path: "curafinance",
      toolAssistant: {
        name: "Bernd Nagel",
        email: "ben@curagita.com",
        phone: "+49 6221 5025-160",
        img: "Bernd-Nagel.jpg",
      },
      list: [
        {
          title: "Übersicht",
          path: "curafinance",
        },
        {
          title: "DCF-Rechnung",
          path: "dcf-rechnung",
          specifiedPath: "curafinance/dcf-rechnung",
        },
        {
          title: "Benchmarking",
          path: "benchmarking",
          specifiedPath: "curafinance/benchmarking",
        },
        {
          title: "Anbindungen",
          path: "finance-anbindungen",
          specifiedPath: "curafinance/anbindungen",
        },
      ],
    },
    {
      title: "Tech",
      toolName: "CuraTech",
      displayedToolname: "CuraTek",
      isTool: true,
      path: "curatech",
      iconName: "monitor_heart",
      icon: "/icons/tech.svg",
      toolAssistant: {
        name: "Andrea Salwat",
        email: "sal@curagita.com",
        phone: "+49 6221 5025-568",
        img: "Andrea-Salwat.jpg",
      },
      list: [
        {
          title: "Übersicht",
          path: "curatech",
        },
        {
          title: "Vergleich",
          path:
            user === "admin" || user === "curagita"
              ? "alle-gerate"
              : "comparison",
          specifiedPath:
            user === "admin" || user === "curagita"
              ? "curatech/vergleich/alle-gerate"
              : "curatech/vergleich/comparison",
          list:
            user === "admin" || user === "curagita"
              ? [
                  {
                    title: "Geräteübersicht",
                    path: "alle-gerate",
                    specifiedPath: "curatech/vergleich/alle-gerate",
                  },
                  {
                    title: "Gerätevergleich",
                    path: "comparison",
                    specifiedPath: "curatech/vergleich/comparison",
                  },
                ]
              : null,
        },
        {
          title: "Anbindungen",
          path: "tech-anbindungen",
          specifiedPath: "curatech/anbindungen",
        },
      ],
    },
    {
      title: "Talent",
      toolName: "CuraTalent",
      iconName: "person_play",
      isTool: true,
      path: "curatalent",
      icon: "/icons/talent.svg",
      list: [
        {
          title: "Übersicht",
          path: "curatalent",
        },
        user !== "candidate"
          ? {
              title: "Profile",
              path: "search",
              toolAssistant: {
                name: "Luisa Schmidt-Tophoff",
                email: "lst@curagita.com",
                phone: "+49 152 0152 5200",
                img: "Luisa-Schmidt-Tophoff.jpg",
              },
              list: [
                {
                  title: "Alle Talente",
                  path: "search",
                },
                ...(user !== "curagita" || user !== "candidate"
                  ? [
                      {
                        title: "Favoriten",
                        path: "favourites",
                      },
                    ]
                  : []),
              ],
            }
          : null,
        {
          title: "Buchungen",
          path: "bookings",
          toolAssistant: {
            name: "Luisa Schmidt-Tophoff",
            email: "lst@curagita.com",
            phone: "+49 152 0152 5200",
            img: "Luisa-Schmidt-Tophoff.jpg",
          },
        },
        {
          title: "Planner",
          path: "planner",
          toolAssistant: {
            name: "Luisa Schmidt-Tophoff",
            email: "lst@curagita.com",
            phone: "+49 152 0152 5200",
            img: "Luisa-Schmidt-Tophoff.jpg",
          },
          list: [
            {
              title: "Prozess",
              path: id
                ? `planner?id=${id}`
                : user == "candidate"
                ? `planner?id=${userDetails._id}`
                : "planner",
            },
            {
              title: "Sprache",
              path: "language",
              specifiedPath: id
                ? `planner/language?id=${id}`
                : user == "candidate"
                ? `planner/language?id=${userDetails._id}`
                : "planner/language",
            },
          ],
        },
        ...(user == "customer" || user == "admin"
          ? [
              {
                title: "Stellen",
                path: "jobs",
                toolAssistant: {
                  name: "Luisa Schmidt-Tophoff",
                  email: "lst@curagita.com",
                  phone: "+49 152 0152 5200",
                  img: "Luisa-Schmidt-Tophoff.jpg",
                },
                list: [
                  {
                    title: "Übersicht",
                    path: "jobs",
                  },
                  {
                    title: "Tabelle",
                    path: "jobs",
                    specifiedPath: "jobs/table",
                  },
                ],
              },
            ]
          : []),
        {
          title: "Anbindungen",
          path: "talent-anbindungen",
          specifiedPath: "curatalent/anbindungen",
        },
      ].filter((item) => item !== null),
    },
    {
      title: "Netz",
      toolName: "curanetz",
      displayedToolname: "Netzaustausch & CurAcademy",
      iconName: "diversity_3",
      path: "curanetz",
      isTool: true,
      list: [
        {
          title: "Übersicht",
          path: "curanetz",
        },
        {
          title: "Events & Fortbildungen",
          path: "events",
          toolAssistant: {
            name: "Anna Scherger",
            email: "ans@curagita.com",
            phone: "+49 6221 5025-410",
            img: "Anna-Scherger.png",
          },
          list:
            [
              {
                title: "Alle Events & Fortbildungen",
                path: "events",
              },
              user === "admin" || user === "curagita" ?
              {
                title: "Teilnehmende",
                path: "teilnehmende",
                specifiedPath: "events/teilnehmende",
              } : null,
              user === "customer" ?
              {
                title: "Anmeldungen",
                path: "Anmeldungen",
                specifiedPath: "events/anmeldungen",
              } : null
            ].filter((item) => item !== null),
        },
        // {
        //   title: "E-Learning",
        //   path: "e-learning",
        //   specifiedPath: "e-learning/videos",
        //   toolAssistant: {
        //     name: "Luisa Schmidt-Tophoff",
        //     email: "lst@curagita.com",
        //     phone: "+49 152 0152 5200",
        //     img: "Luisa-Schmidt-Tophoff.jpg",
        //   },
        // },
        /*  {
          title: "Verbundvorteile",
          path: "partner",
          toolAssistant: {
            name: "Luisa Schmidt-Tophoff",
            email: "lst@curagita.com",
            phone: "+49 152 0152 5200",
            img: "Luisa-Schmidt-Tophoff.jpg",
          },
        },*/
        {
          title: "Anbindungen",
          path: "netz-anbindungen",
          specifiedPath: "curanetz/anbindungen",
        },
      ],
    },
    user == "admin" || user == "customer"
      ? {
          title: "Protect",
          isTool: false,
          iconName: "verified_user",
          toolName: "CuraProtect",
          path: "curaprotect",
          list: [
            {
              title: "Übersicht",
              path: "curaprotect",
            },
            {
              title: "Anbindungen",
              path: "protect-anbindungen",
              specifiedPath: "curaprotect/anbindungen",
            },
          ],
          toolAssistant: {
            name: "Mona Schneider",
            email: "mos@curagita.com",
            phone: "+49 6221 5025-417",
            img: "Mona-Schneider.jpg",
          },
        }
      : null,

    user == "admin" || user == "customer"
      ? {
          title: "HR",
          isTool: false,
          iconName: "hub",
          toolName: "CuraHR",
          path: "curahr",
          toolAssistant: {
            name: "Kathrin Spieler",
            email: "spi@curagita.com",
            phone: "+49 152 32149063",
            img: "Kathrin-Spieler.jpg",
          },
          list: [
            {
              title: "Übersicht",
              path: "curahr",
            },
            {
              title: "Anbindungen",
              path: "hr-anbindungen",
              specifiedPath: "curahr/anbindungen",
            },
          ],
        }
      : null,

    user == "admin" || user == "customer"
      ? {
          title: "Shop",
          isTool: false,
          iconName: "shopping_cart",
          toolName: "CuraShop",
          toolAssistant: {
            name: "Boris Kögler",
            email: "koe@curagita.com",
            phone: "+49 6221 5025-140",
            img: "Boris-Kögler.jpg",
          },
          list: [
            {
              title: "Übersicht",
              path: "curashop",
            },
            {
              title: "Anbindungen",
              path: "shop-anbindungen",
              specifiedPath: "curashop/anbindungen",
            },
          ],
        }
      : null,
    {
      title: "IT",
      toolName: "CuraIT",
      iconName: "desktop_windows",
      isTool: false,
      path: "curait",
      toolAssistant: {
        name: "Jean-Marc Lempp",
        email: "jml@curagita.com",
        phone: "+49 6221 5025-550",
        img: "Jean-Marc-Lempp.png",
      },
      list: [
        {
          title: "Übersicht",
          path: "curait",
        },
        {
          title: "CuraWatch",
          path: "cura-watch",
          toolAssistant: {
            name: "Jean-Marc Lempp",
            email: "jml@curagita.com",
            phone: "+49 6221 5025-550",
            img: "Jean-Marc-Lempp.png",
          },
        },
        {
          title: "Anbindungen",
          path: "it-anbindungen",
          specifiedPath: "curait/anbindungen",
        },
        /*  {
          title: "Tickets",
          path: "tickets",
          toolAssistant: {
            name: "Jean-Marc Lempp",
            email: "jml@curagita.com",
            phone: "+49 6221 5025-550",
            img: "Jean-Marc-Lempp.png",
          },
        },*/
      ],
    },

    user == "admin" || user == "customer"
      ? {
          title: "Scan",
          isTool: false,
          iconName: "radiology",
          toolName: "CuraScan",
          toolAssistant: {
            name: "Boris Kögler",
            email: "koe@curagita.com",
            phone: "+49 6221 5025-140",
            img: "Boris-Kögler.jpg",
          },
          list: [
            {
              title: "Übersicht",
              path: "curascan",
            },
            {
              title: "Anbindungen",
              path: "scan-anbindungen",
              specifiedPath: "curascan/anbindungen",
            },
          ],
        }
      : null,
    user == "admin" || user == "customer"
      ? {
          title: "Fee",
          isTool: false,
          iconName: "donut_small",
          toolName: "CuraFee",
          toolAssistant: {
            name: "Boris Kögler",
            email: "koe@curagita.com",
            phone: "+49 6221 5025-140",
            img: "Boris-Kögler.jpg",
          },
          list: [
            {
              title: "Übersicht",
              path: "curafee",
            },
            {
              title: "Anbindungen",
              path: "fee-anbindungen",
              specifiedPath: "curafee/anbindungen",
            },
          ],
        }
      : null,
    user == "admin" || user == "customer"
      ? {
          title: "Comm",
          isTool: false,
          iconName: "full_coverage",
          toolName: "CuraComm",
          toolAssistant: {
            name: "Boris Kögler",
            email: "koe@curagita.com",
            phone: "+49 6221 5025-140",
            img: "Boris-Kögler.jpg",
          },
          list: [
            {
              title: "Übersicht",
              path: "curacomm",
            },
            {
              title: "Anbindungen",
              path: "comm-anbindungen",
              specifiedPath: "curacomm/anbindungen",
            },
          ],
        }
      : null,
    {
      title: "Consult",
      toolName: "CuraConsult",
      isTool: false,
      iconName: "partner_exchange",
      path: "curavalue",
      toolAssistant: {
        name: "Bernd Nagel",
        email: "ben@curagita.com",
        phone: "+49 6221 5025-160",
        img: "Bernd-Nagel.jpg",
      },
      list: [
        {
          title: "Übersicht",
          path: "curaconsult",
        },
        {
          title: "Anbindungen",
          path: "consult-anbindungen",
          specifiedPath: "curaconsult/anbindungen",
        },
        /* {
          title: "CuraValue",
          path: "curavalue",
        },
        {
          title: "Praxisprofil",
          path: "https://web.umfrageonline.com/s/interaktives_praxisprofil",
          external: true,
        },*/
      ],
    },

    user == "admin" || user == "customer"
      ? {
          title: "Curagita",
          isTool: false,
          iconName: "curagita",
          toolName: "Curagita",
          toolAssistant: {
            name: "Boris Kögler",
            email: "koe@curagita.com",
            phone: "+49 6221 5025-140",
            img: "Boris-Kögler.jpg",
          },
          list: [
            {
              title: "Übersicht",
              path: "curagita",
            },
            {
              title: "Anbindungen",
              path: "curagita-anbindungen",
              specifiedPath: "curagita/anbindungen",
            },
          ],
        }
      : null,
    {
      title: "Konto",
      toolName: "Konto",
      isTool: false,
      iconName: "person",
      list: [
        {
          title: "Profil",
          path: `konto`,
        },
        {
          title: user == "customer" ? "Praxis" : "Firma",
          path: "org",
          specifiedPath: `konto/org`,
        },
        role == "super-admin"
          ? {
              title: "Berechtigungen",
              path: "berechtigungen",
              specifiedPath: "/konto/berechtigungen",
            }
          : null,
        {
          title: "Benachrichtigungen",
          path: "benachrichtigungen",
          specifiedPath: "/konto/benachrichtigungen",
        },
      ].filter((item) => item !== null),
    },
  ].filter((item) => item !== null);

  const [routes, setRoutes] = useState([]);


  useEffect(() => {
    const filteredRoutes = defaultRoutes
      .map((route) => {
        const userPermission =
          userDetails[route.optToolName ?? route.toolName?.toLowerCase()];

        if (userPermission === "none" || userPermission === undefined) {
          const filteredList = route.list.filter((item) => {
            if (
              userDetails.accountType == "CANDIDATE" ||
              userDetails.accountType == "PARTNER"
            ) {
              return item.title == "Übersicht";
            } else
              return item.title == "Anbindungen" || item.title == "Übersicht";
          });

          if (filteredList.length > 0) {
            return { ...route, list: filteredList };
          }
          return null;
        } else {
          // User has rights, return the entire route
          return route;
        }
      })
      .filter((route) => route !== null);

    setRoutes([
      ...filteredRoutes,
      {
        title: "Konto",
        toolName: "Konto",
        isTool: false,
        iconName: "person",
        list: [
          {
            title: "Profil",
            path: `konto`,
          },
          {
            title: userDetails.accountType == "CUSTOMER" ? "Praxis" : "Firma",
            path: "org",
            specifiedPath: `konto/org`,
          },
          userDetails.role == "super-admin"
            ? {
                title: "Berechtigungen",
                path: "berechtigungen",
                specifiedPath: "/konto/berechtigungen",
              }
            : null,
          {
            title: "Benachrichtigungen",
            path: "benachrichtigungen",
            specifiedPath: "/konto/benachrichtigungen",
          },
        ].filter((item) => item !== null),
      },
    ]);
  }, [loggedInUser, userDetails]);

  return { routes };
}
