import { useState, useEffect } from "react";
import { useRouter } from "next/router";
import IconHelper from "../IconMaterial";
import IconWrapper from "../Icons";
import style from "./style.module.scss";
import { Button } from "./Buttons";
import Chip, { Tag } from "../chip";
import InfoModal from "../InfoModal";
import { ClickAwayListener } from "@mui/base";
import Loader from "../Loader";
import { FormAlert } from "./FormAlert";

function Header({ title, label, iconBack, onBack }) {
  return (
    <div className={style.header}>
      <button className={style.title} onClick={onBack} type="button">
        {iconBack && <IconHelper iconName="chevron_left" />}
        {title}
        {label && (
          <div style={{ marginLeft: "24px" }}>
            <Chip label={label} />
          </div>
        )}
      </button>
      <div className={style.actions}>
        <IconWrapper iconName="close" onClick={onBack} />
      </div>
    </div>
  );
}

function MultiStepNavigation({
  count,
  totalSteps,
  onPrev,
  onNext,
  onSubmit,
  disabled,
}) {
  return (
    <div className={style.navButtons}>
      {count > 0 && (
        <Button variant="bg-white" btnText="Zurück" onClick={onPrev} />
      )}
      {count < totalSteps - 1 ? (
        <Button
          btnText="Weiter"
          variant="bg-blue"
          size="large"
          type="button"
          disabled={disabled}
          onClick={onNext}
        />
      ) : (
        <Button
          btnText="Speichern"
          variant="bg-blue"
          size="large"
          disabled={disabled}
          onClick={onSubmit}
        />
      )}
    </div>
  );
}

export default function FormWrapper({
  title,
  children,
  onSubmit,
  label,
  multi,
  submitText,
  disabled,
  iconBack = true,
  mdTitle,
  mdText,
  noAccess,
  titles = [],
  values = {},
  components = [],
}) {
  const router = useRouter();
  const [loading, setLoading] = useState(true);
  const [count, setCount] = useState(0);
  const [closeForm, setCloseForm] = useState(false);
  const [prevValues, setPrevValues] = useState(values);
  const [isDirty, setIsDirty] = useState(true);
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (values) setLoading(false);
  }, [values]);

  useEffect(() => {
    const hasChanged = JSON.stringify(prevValues) !== JSON.stringify(values);

    if (hasChanged) {
      setIsDirty(true);
      setPrevValues(values);
    }
  }, [values, prevValues]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (multi && count < components.length - 1) {
      // Move to the next step in multi-step form
      setCount(count + 1);
    } else {
      // Final submission (single forms and multi-step at last step)
      setSubmitting(true); // Indicate form is submitting
      setLoading(true); // Optional: Show loading indicator
      setIsDirty(false); // Reset isDirty to suppress FormAlert

      try {
        await onSubmit(e); // Execute the form submission logic
      } finally {
        setSubmitting(false);
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [count]);

  const handleCloseModal = () => setCloseForm(!closeForm);
  const handleBack = () => router.back();

  return (
    <>
      {!noAccess && isDirty && !loading && (
        <FormAlert
          isDirty={isDirty}
          isSubmitting={loading}
          setIsDirty={setIsDirty}
        />
      )}

      <div className={style.formContainer}>
        {closeForm && (
          <InfoModal
            action={handleBack}
            closeModal={handleCloseModal}
            info={mdTitle ?? "Ohne Speichern schließen"}
            extra={
              mdText ??
              "Wollen Sie die Änderungen verwerfen und die Seite schließen?"
            }
          />
        )}
        <Header
          title={title}
          label={label}
          iconBack={iconBack}
          onBack={handleBack}
        />

        {loading ? (
          <Loader />
        ) : noAccess ? (
          <p className="mt-4">
            Dieser Benutzer ist nicht berechtigt, diese Seite anzuzeigen.
          </p>
        ) : (
          <form onSubmit={handleSubmit}>
            <div className="d-flex gap-16 mt-32">
              {titles.map((t, index) => (
                <Tag
                  key={index}
                  label={t}
                  active={index === count}
                  onClick={() => setCount(index)}
                />
              ))}
            </div>

            <div className={style.content}>
              {multi ? components[count] : children}
            </div>
            <div className={multi ? style.navButtons : style.saveBtn}>
              {multi && count > 0 && (
                <Button
                  variant="bg-white"
                  btnText="Zurück"
                  onClick={() => setCount(count - 1)}
                />
              )}
              <Button
                btnText={
                  multi && count < components.length - 1
                    ? "Weiter"
                    : submitText ?? "Speichern"
                }
                variant="bg-blue"
                size="large"
                type="submit"
                disabled={disabled}
              />
            </div>
          </form>
        )}
      </div>
    </>
  );
}
