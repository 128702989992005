import { useMemo } from 'react';

function useApiLink() {
  // Define the base URL and API endpoints
  const baseUrl = process.env.NEXT_PUBLIC_BASE_URL; // Get the base URL from environment variables
  const apiEndpoint = useMemo(() => {
    if (baseUrl) {
      return baseUrl
    } else{
    // Default to a fallback API endpoint if the base URL doesn't match
      return 'https://staging.radiologie.de';
    }
  }, [baseUrl]);
  return apiEndpoint;
}

export default useApiLink;
