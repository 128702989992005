
import { useRef, useState, useEffect } from "react";
import style from "./style.module.scss";
import { ClickAwayListener } from "@mui/base";
import IconHelper from "../IconMaterial";
import SearchBar from "./SearchBar";
import { BeatLoader } from "react-spinners";
import CustomCheckbox from "./CustomCheckbox";
import { Button } from "./Buttons";


export default function MultiSelect({
    options,
    emptyOptionText,
    selected,
    setSelected,
    label,
    error,
    filterPlaceholder,
    isRequired,
  }) {
    const [show, setShow] = useState(false);
    const [filterText, setFilterText] = useState("");
  
    const toggleDropdown = () => setShow(!show);
  
    const handleSelect = (option) => {
      if (selected.includes(option)) {
        setSelected(selected.filter((item) => item !== option));
      } else {
        setSelected([...selected, option]);
      }
    };
  
    const filteredOptions = options?.filter((option) =>
      option.toLowerCase().includes(filterText.toLowerCase())
    );
  
    return (
      <ClickAwayListener onClickAway={() => setShow(false)}>
        <div className={style.selectWrapper}>
          <div
            className={`${style.selectBtn} ${error ? style.error : null}`}
            onClick={toggleDropdown}
          >
            <div className={style.selectedItemChip}>
              {(!selected || selected.length === 0) && (
                <span>
                  <span className={style.selectInputLabel}> {label} </span>
                  {isRequired && <span className="text-error">*</span>}
                </span>
              )}
              {selected &&
                selected.map((item, index) => (
                  <p key={index} className={style.selectedItem}>
                    {item}
                    {index < selected.length - 1 && ", "}
                  </p>
                ))}
            </div>
            <IconHelper
              iconName="keyboard_arrow_down"
              className={style.chevron}
            />
          </div>
  
          {show && (
            <div className={style.selectList}>
              <div className={style.selectInputWrapper}>
                <SearchBar
                  autoFocus
                  value={filterText}
                  onChange={(e) => setFilterText(e.target.value)}
                />
              </div>
              {emptyOptionText &&
                ((filteredOptions && filteredOptions?.length === 0) ||
                  !filteredOptions) && (
                  <p className={style.emptyOptionText}>{emptyOptionText}</p>
                )}
              {filteredOptions?.map((option, index) => (
                <CustomCheckbox
                  key={index}
                  checked={selected.includes(option)}
                  value={option}
                  label={option}
                  extraClassName={style.selectOption}
                  onChange={() => handleSelect(option)}
                />
              ))}
            </div>
          )}
        </div>
      </ClickAwayListener>
    );
  }