import { forwardRef, useRef, useEffect } from "react";
import style from "./style.module.scss";
import { Button } from "./Buttons";
import { NumericFormat } from "react-number-format";
import IconWrapper from "../Icons";
import { useState } from "react";
import PhoneInput from "react-phone-input-2";
import { max } from "lodash";

const FloatingLabelInput = forwardRef(
  ({ validate = "none", isRequired, onChange, type, ...props }, ref) => {
    const [error, setError] = useState(false);

    const validateInput = (value) => {
      if (validate === "none") {
        return true;
      }
      if (isRequired && !value) {
        return false;
      }
      if (isRequired && type == "email") {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(value);
      }
      return true;
    };

    const handleBlur = (e) => {
      const isValid = validateInput(e.target.value);
      setError(!isValid);
    };

    // const updateContact = (va)
    return type == "tel" ? (
      <PhoneInput
        specialLabel={props.label}
        value={props.value}
        country={"de"}
        onChange={(val) =>
          onChange({ target: { name: props.name, value: val } })
        }
      />
    ) : (
      <div className={`${error ? style.error : ""} ${style.floatingLabel}`}>
        <input
          {...props}
          ref={ref}
          type={type}
          onBlur={handleBlur}
          onChange={onChange}
          className={`${props.value ? style.notEmpty : ""} `}
        />
        <label>
          {props.label}
          {isRequired && <span className="text-error">*</span>}
        </label>
      </div>
    );
  }
);

export default FloatingLabelInput;

function FloatingLabelTextarea({
  name,
  value = "",
  wordLimit = 500,
  onChange,
  ...props
}) {
  const [maxWords, setMaxWords] = useState(false);
  const wordCount = value
    .trim()
    .split(/\s+/)
    .filter((word) => word.length > 0).length;

  const handleChange = (e) => {
    const inputText = e.target.value;
    onChange(e);
    const words = inputText.trim().split(/\s+/);

    if (words.filter((word) => word.length > 0).length > wordLimit) {
    }
  };
  useEffect(() => {
    if (wordCount > wordLimit) {
      setMaxWords(true);
    } else {
      setMaxWords(false);
    }
  }, [value]);
console.log(maxWords, "value")
  return (
    <div>
      <div className={style.floatingLabel}>
        <textarea
          name={name}
          value={value}
          onChange={handleChange}
          {...props}
          className={`${value ? style.notEmpty : ""} ${
            maxWords ? style.error : ""
          }`}
          style={props.style}
        />
        <label>{props.label}</label>
      </div>
      <p className="text-right text-dark-gray">
        {wordCount}/{wordLimit}
      </p>
    </div>
  );
}

function AddNewInput({
  options = [],
  title,
  label,
  field,
  values,
  setValues,
  btnText,
}) {
  const inputRefs = useRef([]);

  const handleChange = (value, index) => {
    const updatedItem = options?.map((item, i) => (i === index ? value : item));
    setValues({ ...values, [field]: updatedItem });
  };

  const addItem = () => {
    setValues({
      ...values,
      [field]: [...values[field], ""],
    });
  };

  const handleKeyDown = (e, index) => {
    if (e.key == "Enter") {
      e.preventDefault();
      if (index < options.length - 1) {
        console.log("here", index + 1);
        inputRefs.current[index + 1]?.focus();
      } else {
        addItem();
        setTimeout(() => {
          inputRefs.current[index + 1]?.focus();
        }, 0);
      }
    }
  };

  const handleDelete = (index) => {
    const updatedItem = options.filter((_, i) => i !== index);
    setValues({ ...values, [field]: updatedItem });
  };
  console.log(options, "options", title);
  return (
    <div className={style.section}>
      <h3 className={style.sectionTitle}>{title}</h3>
      <div className={style.formGroup}>
        {options?.map((el, index) => (
          <div className="d-flex gap-24 align-items-center">
            <div className="input-md" key={index}>
              <FloatingLabelInput
                label={label}
                value={el}
                ref={(el) => (inputRefs.current[index] = el)}
                onKeyDown={(e) => handleKeyDown(e, index)}
                onChange={(e) => handleChange(e.target.value, index)}
              />
            </div>

            <IconWrapper
              iconName={"delete"}
              onClick={() => handleDelete(index)}
            />
          </div>
        ))}
        {btnText && (
          <Button
            variant="bg-none"
            iconName="add"
            btnText={btnText}
            onClick={addItem}
          />
        )}
      </div>
    </div>
  );
}

function PricingInput({ name, value, onChange, onKeyDown, id }) {
  return (
    <div className={style.pricingInput}>
      <NumericFormat
        id={id}
        decimalSeparator={","}
        thousandSeparator={"."}
        placeholder={"0"}
        value={value}
        name={name}
        onKeyDown={onKeyDown}
        onValueChange={(e) => onChange(e.value)}
      />
      <p className={style.currency}>€</p>
    </div>
  );
}
export function Links({ values, setValues, fieldName }) {
  const addLink = () => {
    setValues((prevState) => ({
      ...prevState,
      links: [...prevState.links, { url: "", name: "" }],
    }));
  };
  const updateLink = (name, value, index) => {
    const updatedLinks = values.links.map((link, i) =>
      i === index ? { ...link, [name]: value } : link
    );
    setValues({ ...values, links: updatedLinks });
  };

  const deleteLink = (index) => {
    const updatedLinks = values.links.filter((_, i) => i !== index);
    setValues({ ...values, links: updatedLinks });
  };

  return (
    <>
      {" "}
      {values.links?.map((el, index) => (
        <div className="d-flex gap-16 align-items-center" key={index}>
          <div className="input-md">
            <FloatingLabelInput
              label="URL"
              type="url"
              name="url"
              value={el.url}
              onChange={(e) => updateLink("url", e.target.value, index)}
            />
          </div>
          <div className="input-sm">
            <FloatingLabelInput
              label="Anzeigename"
              type="text"
              name="name"
              value={el.name}
              onChange={(e) => updateLink("name", e.target.value, index)}
            />
          </div>

          <IconWrapper iconName={"delete"} onClick={() => deleteLink(index)} />
        </div>
      ))}
      <Button
        variant="bg-none"
        size={"none"}
        btnText={"Link"}
        iconName={"add"}
        onClick={() => addLink()}
      />
    </>
  );
}

export { FloatingLabelInput, FloatingLabelTextarea, AddNewInput, PricingInput };
