import { useState } from "react";
import style from "../../../styles/Header.module.scss";
import { useLinkPrefix } from "../CustomHooks/others";
import { ActiveLineIcon } from "./components";
import { useRouter } from "next/router";
import IconHelper from "../IconMaterial";

export default function Navigation({
  nav,
  navRef,
  styles,
  tool,
  swappedRoute, 
  onNavClick,
}) {
  const router = useRouter();
  const prefix = useLinkPrefix();
  const [hovering, setHovering] = useState(false);
  
  const isActive =
    (!swappedRoute && nav.title === tool) || nav === swappedRoute;

   
  return (
    <div
      ref={navRef}
      className={style.navBtnWrapper}
      onClick={() => {
        onNavClick(); // Call the handler to reset swappedRoute
        router.push(
          `${prefix}/${
            nav.list[0]?.specifiedPath
              ? nav.list[0]?.specifiedPath
              : nav.list[0]?.path
          }`
        );
      }}
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
    >
      <ActiveLineIcon
        color={isActive ? "#009FE3" : "#DCDCDC"}
        className={isActive || hovering ? "visible" : "invisible"}
      />
      <button
        className={style.navBtn}
        onMouseEnter={() => setHovering(true)}
        onMouseLeave={() => setHovering(false)}
      >
        {nav.iconName == "curagita" ? (
          <img alt="curagita" src="/curagita-logo.svg" className={style.logo} />
        ) : (
          <IconHelper
            iconName={nav.iconName}
            size={32}
            className={hovering || isActive ? style.activeIcon : null}
          />
        )}
        <p className={style.navText}> {nav.title}</p>
      </button>
    </div>
  );
}
