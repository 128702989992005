import { useState, useEffect } from "react";
import IconHelper from "../IconMaterial";
import style from "./style.module.scss";

export default function CustomCheckbox({
  label,
  customLabel,
  children,
  extraClassName = "",
  type = "checkbox",
  error,
  checked,
  isRequired,
  ...props
}) {
  return (
    <>
      <div
        className={
          style.customCheckbox +
          " " +
          (error ? style.checkboxError : "") +
          " " +
          extraClassName
        }
      >
        <input type={type} id={props.name} checked={checked} {...props} />
        {children ?? <label htmlFor={props?.name}>{label}</label>}
        {isRequired && <span className="text-error">*</span>}
      </div>
    </>
  );
}
export function LabelCheckbox({
  value,
  name,
  onChange,
  checkedVal,
  styles,
  label,
  readOnly,
}) {
  const [checked, setChecked] = useState(false);
  useEffect(() => {
    setChecked(checkedVal);
  }, [checkedVal]);

  const handleClick = () => {
    setChecked(!checked);
    onChange(value, !checked, name);
  };
  return (
    <button
      type="button"
      onClick={readOnly ? null : handleClick}
      style={styles}
      className={`${style.checkPill} ${checked && style.checked}`}
    >
      {checked && <IconHelper iconName={"check"} className={style.icon} />}{" "}
      <span>{label ?? value}</span>
    </button>
  );
}
export function ToggleSwitch({ onChange, name = "", isToggled }) {
  const [toggled, setToggled] = useState(false);

  useEffect(() => {
    setToggled(isToggled);
  }, [isToggled]);

  const handleChange = () => {
    setToggled(!toggled);
    onChange(name, !toggled);
  };

  return (
    <button
      className={`${style.toggleSwitch} ${toggled ? style.on : style.off}`}
      onClick={handleChange}
    >
      <p className={style.toggleThumb}>
        <IconHelper
          iconName={toggled ? "check" : "close"}
          className={style.icon}
        />
      </p>
    </button>
  );
}
