import { useEffect } from "react";
import style from "../../styles/Modal.module.scss";
import { Button } from "./FormComponent";
import IconWrapper from "./MaterialIcon";

export default function ErrorModal({
  closeModal,
  title,
  info,
  exitForm,
  showReview
}) {

  useEffect(() => {
      document.body.style.overflow = "hidden";
      document.body.style.position = "fixed";

    // Cleanup when the component unmounts
    return () => {
      document.body.style.overflow = "auto";
      document.body.style.position = "static";
    };
  }, []);

  return (
    <div className={style.myModal} onClick={closeModal}>
      <div
        className={style.myErrorModalContent}
        onClick={(e) => e.stopPropagation()}
      >
        <div className={style.myErrorModalInnerContent}>
          <div className={style.times} onClick={closeModal}>
            <IconWrapper iconName={"close"} onClick={closeModal} />
          </div>
          <p className={style.main_text}>{title ?? "Fehler"}</p>
          {info ? (
            <p className={style.sub_text}>{info}</p>
          ) : (
            <p className={style.sub_text}>
              Beim Aktualisieren des Formulars ist ein Fehler aufgetreten.
              Bitte versuchen Sie es erneut.
            </p>
          )}
          <div className={style.btn_group}>
            {showReview && (
              <button className={style.error_btn}>Rezension</button>
            )}
            <Button
              btnText="Schließen"
              variant="bg-blue"
              onClick={exitForm ? exitForm : closeModal}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
